@import 'navigation';
@import 'header';
@import 'footer';
@import 'formular';
@import 'listen';
@import 'icons';
@import 'buttons';
@import 'kunden';
@import 'bewertungen';
@import 'schritte';
@import 'cookies';
@import 'brillen';
@import 'leistungen';
@import 'hygiene';
.content {
    width: 100%;
    max-width: 120rem;
    margin: 0 auto;
    padding: 2rem;
    @media(min-width: $mobile) {
        padding: 3rem; } }
.row__image {
    margin-bottom: 2rem; }
.date {
    font-size: 1.3rem;
    font-family: $font;
    color: $grau;
    font-style: normal;
    display: block;
    margin-top: 1.5rem;
    padding-bottom: 1.5rem;
    margin-bottom: 2rem;
    position: relative;
    &:after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 150px;
        height: 1px;
        background: $grau; }
    strong {
        font-weight: 700;
        font-family: inherit; } }
.grey {
    background: lighten($hellgrau, 10%); }

.mobilefull {
    padding: 0; }

.lightgrey {
    background: $hellgrau-bg; }

.legal {
    color: $grau;
    margin-top: 2rem; }

.desktop {
    display: none!important;
    @media(min-width: $mobile) {
        display: block!important; } }
.mobile {
    @media(min-width: $mobile) {
        display: none!important; } }
