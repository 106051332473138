#glueckliche_kunden {
    background: $blau;
    color: white;
    padding: 3rem 2rem;
    h3 {
        width: 100%;
        text-align: center;
        max-width: 80rem;
        display: block;
        margin: 0 auto;
        font-size: 2rem;
        color: white;
        font-family: $font;
        line-height: 1.4; } }
