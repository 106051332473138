#kundenzufriedenheit {
    background: $gradient4;
    padding: 3rem 2rem;
    min-height: 40rem;
    display: flex;
    align-items: center;
    justify-content: center; }
.kundenzitate {
    // margin: 0 auto
    width: $tablet;
    max-width: 100%;
    .google {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin: 2rem auto 2rem;
        justify-content: center;
        img {
            margin-left: 1rem; } }
    .bewertung {
        padding: 2rem;
        text-align: center;
        @media(min-width: $mobile) {
            margin: 0 1rem; }
        p {
            font-size: 1.6rem;
            margin-bottom: 1rem; }
        &__name {
            font-style: normal;
            color: $grau;
            font-size: 1.1rem; }
        &__sterne {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 1.6rem;
            img {
                width: 1.6rem;
                margin: 0 0.1rem; } } } }

#bewertungen {
    margin-left: 2rem;
    margin-right: 2rem;
    position: relative;
    .prevArrow, .nextArrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer; }
    .prevArrow {
        left: -2rem; }
    .nextArrow {
        right: -2rem; } }

