
#schritte {
    margin: 0;
    background: $gradient4;
    position: relative;
    .content {
        text-align: center; }
    h2 {
        text-align: center;
        // text-transform: uppercase
        color: $blau!important; } }


