.phone__icon {
    margin-right: 1rem;
    position: relative;
    width: 1.2rem; }
.download__icon {
    position: relative;
    margin-right: 1rem;
    top: 3px; }

.icon__next {
    position: relative;
    margin-left: 1rem; }
