#header {
    padding: 0; }
.header {
    display: block;
    position: relative;

    .stoerer {
        position: absolute;
        left: 3rem;
        bottom: 3rem;
        max-width: 20rem;
        @media (max-width: $tablet) {
            bottom: -10%;
            left: 2rem;
            max-width: 30vw;
            width: 20rem; } }
    .stoerer-gl {
        position: absolute;
        left: 55%;
        top: 55%;
        max-width: 20rem;
        @media (max-width: $tablet) {
            bottom: 2rem;
            top: auto;
            right: 2rem;
            max-width: 30vw;
            width: 20rem; } }
    @media (max-width: $tablet) {
        margin-bottom: 5%!important; } }

.content {
    &#headline--content {
        padding: 0; }
    .headline--container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        background: url('../img/img-bg.svg') center no-repeat;
        background-size: contain;
        padding: 17% 0 5%;
        text-align: center;
        margin-top: -19rem;
        z-index: 1;
        position: relative;
        h1 {
            font-size: 4rem;
            // text-transform: uppercase
            margin-bottom: 0;
            color: $blau; }
        h3 {
            font-size: 2.4rem!important;
            font-weight: 400;
            color: $blau;
            line-height: 1.2; }
        @media (max-width: $tablet) {
            h1 {
                font-size: 3rem; }
            h3 {
                font-size: 2.4rem!important; } }
        @media (max-width: $mobile-s) {
            padding: 19% 0 4%;
            margin-top: -11rem;
            // margin-top: 0
            // padding: 3rem 4rem
            h1 {
                font-size: 1.7rem;
                margin-bottom: 0rem; }
            h3 {
                font-size: 1.2rem!important; } } } }
