
nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    a {
        display: block;
        margin: 0;
        padding: 0; }
    .logo {
        //width: 20rem
        display: block;
        margin: 0 auto;
        height: 60px;
        width: auto; }
    .main--nav {
        background: $blau;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin: 0;
        flex: 1 0 0;
        list-style-type: none;
        margin-left: 0;
        color: white;
        padding: 2rem;
        li {
            margin: 0;
            transition: all 300ms ease;
            // &:nth-child(1), &:nth-child(2)
            //     border-right: 1px solid white
            a {
                transition: all 300ms ease; }
            &:hover {
                a:not(.phone__number) {
                    background: $dunkelblau; } }
            @media (max-width: $mobile) {
                display: none;
                &:last-child {
                    display: block; } } }
        a {
            text-decoration: none;
            color: white;
            text-transform: uppercase;
            padding: 1rem 2rem; } }
    .nav--logo {
        padding: 1rem;
        text-align: center;
        width: 30rem; }


    @media (min-width: $mobile) {
        .logo {
 } } }            //width: 22rem

