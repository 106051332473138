ul.checklist, ul.steps {
    list-style-type: none;
    font-size: 1.6rem;
    margin: 2rem 0;
    font-family: $font;
    li {
        font-family: $font;
        font-weight: 400;
        margin-left: 3rem;
        position: relative;
        margin-bottom: 2rem;
        &:before {
            content: "";
            width: 23px;
            height: 26px;
            background: url('../img/icn--check.svg') center no-repeat;
            background-size: contain;
            position: absolute;
            left: -3rem;
            top: -4px; } } }


ul {
    margin: 1rem 0;
    li {
        font-size: 1.6rem;
        margin-left: 2rem;
        margin-bottom: 0.5rem; } }
.icon--list {
    list-style-type: none;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin: 4rem 0 2rem;
    li {
        margin: 0;
        width: calc(100% / 3);
        text-align: center;
        i {
            margin: 1rem auto;
            width: 18rem;
            height: 18rem;
            border-radius: 50%;
            background: $blau;
            display: flex;
            align-items: center;
            justify-content: center; }
        span {
            display: block;
            text-align: center;
            font-size: 2rem;
            font-weight: 700;
            max-width: 23rem;
            margin: 2rem auto 0; } }
    @media (max-width: $tablet) {
        li {
            padding: 1rem;
            i {
                width: 10rem;
                height: 10rem;
                img {
                    max-width: 5rem; } }
            span {
                font-size: 1.6rem; }
            &:last-child {
                i {
                    img {
                        max-width: 8rem; } } } } }
    @media (max-width: $mobile-s) {
        display: block;
        li {
            padding: 1rem;
            width: 100%;
            i {
                width: 10rem;
                height: 10rem;
                img {
                    max-width: 5rem; } }
            span {
                font-size: 1.6rem; }
            &:last-child {
                i {
                    img {
                        max-width: 8rem; } } } } } }

.image--list {
    list-style-type: none;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin: 3rem auto;
    max-width: 85rem;
    li {
        margin: 0;
        display: block;
        flex: 1 0 33%;
        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            width: calc(100% / 3); }
        img {
            height: auto; }

        span {
            margin-top: 2rem;
            display: block;
            text-align: center; } }
    @media (max-width: $mobile-s) {
        display: block;
        li {
            display: flex;
            align-items: center;
            width: 100%;
            img {
                width: 50%;
                margin-right: 1rem; }
            span {
                width: 45%;
                text-align: left; } } } }

