#leistungen {
    background: $blau;
    padding: 6rem 2rem;
    // background-image: url('../img/icn-bf.svg')
    background-size: 27rem;
    background-position: left bottom;
    background-repeat: no-repeat;
    .content {
        margin: 0 auto;
        max-width: $tablet;
        h2 {
            color: #ffffff;
            text-align: center;
            // text-transform: uppercase
            font-size: 2.8rem;
            margin-bottom: 4rem; }
        .checklist {
            columns: 2;
            column-gap: 3rem;
            @media (max-width: $tablet) {
                columns: 1; }
            li {
                color: $weissblau;
                &::before {
                    color: #ffffff; } } } } }
