#brillen {
    text-align: center;
    position: relative;
    h2 {
        // text-transform: uppercase
        font-size: 3rem; }
    .soft--row {
        // padding: 2rem 2rem
        display: flex;
        flex-wrap: wrap;
        @media (max-width: $tablet) {
            padding: 2rem 0; }
        .col--1 {
            text-align: left;
            flex: 1 0 75%;
            padding-right: 2rem;
            * {
                text-align: left!important; }
            p {
                margin: 0; } }
        img {
            display: block; }
        .col--2 {
            flex: 1 0 15%; } } }
#hersteller--logos {
    width: 110px;
    // margin-top: -10px
    position: relative; }
.preise {
    .headline--container {
        padding: 3rem 3rem;
        margin-top: 0; }
    h3 {
        font-family: $heading;
        color: $blau;
        font-size: 3rem!important; }
    .preis--container {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin-top: 2rem;
        .preis--box {
            flex: 1 0 28%;
            padding: 2rem;
            max-width: calc(100% / 3);
            background: $hellblau;
            text-align: left;
            &:nth-child(2) {
                background: lighten($hellblau, 10%); }
            &:nth-child(3) {
                background: $weissblau; }
            strong {
                font-size: 1.6rem!important;
                margin-bottom: 1rem;
                display: block; }
            p {
                margin-bottom: 2rem; }
            .preislabel {
                display: flex;
                align-items: center;
                justify-content: space-between;
                * {
                    font-size: 1.6rem; }
                img.zeiss {
                    width: 6rem;
                    margin-right: 2rem; }
                img.hoya {
                    width: 10rem;
                    margin: 0 2rem 0 0; }
                .small {
                    color: $rot;
                    text-align: right; }
                h4 {
                    color: $rot;
                    font-size: 5rem;
                    display: inline-block;
                    font-family: $heading;
                    text-align: right;
                    line-height: 1; }
                p {
                    text-align: right;
                    margin: 0; } }

            @media (max-width: $tablet) {
                flex: 1 0 100%;
                max-width: 100%; } } } }
