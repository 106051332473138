#danke {
    min-height: 30rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    h1 {
        color: $blau;
        position: relative; }

    hr {
        width: 100%;
        border: none;
        height: 1px;
        background: $hellgrau;
        margin: 1rem 0 2rem; }
    .buchungsnummer {
        color: $blau; }
    h2 {
        color: black;
        pointer-events: none;
        text-decoration: none; }
    @media (min-width: $mobile) {
        padding: 4rem;
        display: flex;
        hr {
            display: none; }
        .col-1 {
            flex: 1 0 0;
            padding-right: 3rem;
            h2 {
                font-size: 3rem;
                .phone__icon {
                    width: 3rem;
                    height: 3rem; } } }
        .col-2 {
            padding: 2rem;
            border: 1px solid $blau;
            background: #fff;
            //margin: 3rem
            // flex: 1 0 auto
            h2 {
                font-size: 3rem; } }
        .main__button_content {
            display: inline-block; } } }

#schritte {
    h2 {
        color: black;
        margin-bottom: 0; }
    @media (min-width: $mobile) {
        padding: 4rem; } }
