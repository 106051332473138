footer {
    padding: 3rem;
    background: $hellgrau-bg;
    background: white;
    text-align: center;
    .icon--row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 2rem 0;
        .left {
            display: flex;
            align-items: center;
            width: 30%; }
        .center {
            width: 40%;
            text-align: center; }
        .right {
            width: 30%;
            text-align: right; } }
    .link--row {
        margin: 3rem 0 6rem;
        a {
            text-decoration: none;
            font-size: 1.4rem;
            padding: 0 2rem;
            border-right: 1px solid black;
            font-weight: 400;
            &:last-child {
                border-right: none; } } }
    .legal--row {
        .legal {
            font-size: 1.2rem;
            text-align: left; } }
    @media (max-width: $mobile-s) {
        .icon--row {
            flex-wrap: wrap;
            justify-content: center;
            .center {
                width: 100%;
                order: 1;
                margin-bottom: 3rem; }
            .left {
                order: 2;
                width: auto; }
            .right {
                width: auto;
                order: 3; } }
        .link--row {
            margin: 3rem 0;
            a {
                font-size: 1.4rem;
                padding: 0 0.5rem;
                &:first-child {
                    padding-left: 0; }
                &:last-child {
                    padding-right: 0; } } } } }




.social {
    width: 5rem;
    height: 5rem;
    display: inline-block;
    border: 1px solid black;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-right: 2rem;
    transition: all 300ms ease;
    img {
        opacity: 1;
        transition: opacity 300ms ease; }

    &:hover {
        border-color: $grau;
        img {
            opacity: 0.7; } } }




