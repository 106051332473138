form {
    max-width: 100%;
    overflow-x: hidden;
    background: #fff;
    z-index: 0;
    position: relative;
    border: 2px solid $blau;
    .form__header {
        background: $blau;
        margin-bottom: 2rem;
        text-decoration: none;
        text-align: center;
        padding: 2rem;
        font-size: 3.6rem;
        .form--heading {
            color: white;
            margin-bottom: 0;
 }            // text-transform: uppercase
        h4 {
            color: white;
            font-size: 1.6rem;
            font-weight: 600; }
        .step-2-content {
            display: none; } }

    .form__content {
        padding: 2rem;
        .step--1 {
            display: none;
            .checked {
                color: $blau;
                font-weight: 700;
                text-align: left;
                position: relative;
                margin-left: 3.5rem;
                margin-bottom: 3rem;
                &:before {
                    content: "";
                    width: 2rem;
                    height: 2rem;
                    border-radius: 50%;
                    border: 2px solid $blau;
                    position: absolute;
                    left: -3.5rem;
                    top: 0; }
                &:after {
                    content: "";
                    width: 2.2rem;
                    height: 2.2rem;
                    background: url('../img/icn--check.svg') center no-repeat;
                    background-size: contain;
                    position: absolute;
                    left: -3rem;
                    top: -0.2rem; } } }
        .step--2 {
            display: block; }
        .question {
            display: none;
            &.active {
                display: block; } }
        strong {
            margin-bottom: 0.5rem;
            display: block;
            font-size: 1.8rem;
            font-family: $font;
            text-align: center;
            line-height: 1.2; }
        * {
            font-size: 1.6rem; }
        .btn-outline {
            display: block;
            width: 100%;
            padding: 1rem 2rem;
            color: $blau;
            text-align: center;
            border: 2px solid $hellgrau;
            //border-radius: 10rem
            // margin-bottom: 1rem
            transition: all 200ms ease;
            cursor: pointer;
            font-size: 1.8rem;
            &:hover {
                // background: $blau
                // color: white
                border-color: $blau; } }
        input[type="radio"] {
            margin-bottom: 0;
            display: none;
            &:checked {
                &~.btn-outline {
                    border-color: $blau; } } }
        input[type="text"], input[type="number"],input[type="email"] {
            margin-bottom: 0;
            &:focus {
                outline: none; }

            outline: none;
            border: 2px solid $hellgrau;
            padding: 1rem 2rem;
            width: 100%;
            // margin-bottom: 1rem
            //border-radius: 3rem
            font-size: 1.6rem;
            text-align: left; }
        .main__button {
            font-size: 1.8rem;
            margin-bottom: 2rem; }
        .inputGroup {
            display: flex;
            align-items: center;
            position: relative;
            flex-wrap: wrap;
            margin-bottom: 1rem;
            .toggle {
                margin-right: 1rem; }
            #check, #checksession {
                display: none;
                &:checked {
                    &~label {
                        .toggle {
                            &:after {
                                left: 2.2rem;
                                background: $blau; } } } } }
            &.success {
                input {
                    border-color: $success;
                    color: $success;
                    display: block; }
                &:before {
                    content: "";
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 4rem;
                    height: calc(100% - 4px);
                    //border-radius: 0 3rem 3rem 0
                    background: url('../img/icn-checkmark-success.svg') center no-repeat;
                    background-size: 2rem;
                    background-color: white;
                    border: 2px solid $success;
                    border-left: none; } } }
        .toggle_check {
            display: flex;
            align-items: center;
            span {
                font-size: 1.4rem;
                line-height: 1.4; } } } }

.alert {
    font-weight: 700;
    color: $blau;
    margin: 1rem 0;
    text-align: center;
    display: block;
    min-width: 100%; }

#bullets {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 380px;
    margin: 1rem auto;
    padding: 0 2rem 3rem;
    .bullet {
        display: block;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        background: #fff;
        border: 2px solid $grau;
        position: relative;
        transition: all 200ms ease;
        &:before {
            transition: all 200ms ease;
            content: "";
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%) scale(0);
            width: 75%;
            height: 75%;
            border-radius: 50%;
            background: $grau;
            opacity: 0; }
        &:after {
            transition: all 200ms ease;
            right: 100%;
            content: "";
            position: absolute;
            width: 180%;
            height: 2px;
            z-index: -1;
            background: $grau;
            top: 50%;
            transform: translateY(-50%); }
        &.active {
            border: 2px solid $blau;
            &:before {
                opacity: 1;
                background: $blau;
                transform: translate(-50%,-50%) scale(1); }
            &:after {
                background: $blau; } }
        &:first-child {
            &:after {
                display: none; } } } }
input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none; }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    box-shadow: 0 0 0px 1000px #fff inset;
    transition: background-color 5000s ease-in-out 0s; }

.error {
    color: red;
    margin: 1rem 0; }

#formularbereich {
    transition: all 300ms ease;
    @media (min-width: $max-width) {
        overflow-y: auto;
        position: fixed;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        padding: 0;
        width: 44rem;
        z-index: 999;
        padding-left: 4rem;
        .form--toggle {
            cursor: pointer;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 8rem;
            height: 6rem;
            background: url('../img/icn--next-white.svg'), $blau;
            background-position: left 25% center;
            background-repeat: no-repeat;
            background-size: 1.5rem;
            z-index: -1;
            border-radius: 4rem;
            box-shadow: inset -4rem 0 1rem rgba(black, 0.4);
            transition: all 300ms ease;
            &:hover {
                background-position: left 30% center; } }
        &.toggled {
            transform: translateX(40rem) translateY(-50%);
            .form--toggle {
                background: url('../img/icn--prev-white.svg'), $blau;
                background-size: 1.5rem;
                background-repeat: no-repeat;
                background-position: left 25% center;
                &:hover {
                    background-position: left 20% center; } } }

        &.direct {
            .step-1-content, .step--1 {
                display: none; }
            .step-2-content, .step--2 {
                display: block; } }


        form {
            .form__header {
                .form--heading {
                    font-size: 3rem; } } }
        @media (max-height: 700px) {
            top: 8rem;
            max-height: calc(100vh - 8rem);
            transform: translateY(0); } } }
