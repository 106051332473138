#hygiene {
    background: $weissblau;
    text-align: center;
    .content {
        h3, h2, p {
            max-width: 800px;
            margin: 0 auto 2rem; }
        .soft--row {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            .hygiene--icon {
                flex: 1 0 20%;
                padding: 2rem;
                img {
                    width: 150px;
                    min-width: 150px;
                    margin-bottom: 2rem; } }
            @media (max-width: $mobile) {
                .hygiene--icon {
                    flex: 1 0 40%; } }
            @media (max-width: $mobile-s) {
                .hygiene--icon {
                    img {
                        width: 100px;
                        min-width: 100px; } } } } } }
