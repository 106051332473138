@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap');
@font-face {
    font-family: 'The Dolbak Spot';
    src: url('../favicon/thedolbakspot-webfont.woff2') format('woff2'), url('../favicon/thedolbakspot-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal; }
* {
    font-family: $font;
    font-weight: 400; }

h1, h2 {
    font-size: 2.2rem;
    font-weight: bold;
    line-height: 1.3;
    margin-bottom: 2rem;
    font-family: $heading;
    color: $blau;
    @media not all and (min-resolution: .001dpcm) {
        letter-spacing: -2px; } }

h3 {
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 1.6;
    @media (min-width: $mobile) {
        font-size: 1.8rem; } }
p, a {
    font-size: 1.6rem;
    line-height: 1.4;
    @media (min-width: $mobile) {
        font-size: 1.6rem; } }
@media (min-width: $mobile) {
    ul {
        li {
            font-size: 1.6rem!important; } }
    h1, h2 {
        font-size: 4rem; }
    h3 {
        font-size: 2rem!important; } }

a {
    color: black;
    font-weight: 600; }

em {
    font-family: $font; }

strong {
    font-size: inherit;
    font-weight: 700; }

.legal {
    font-size: 1.3rem; }


