button {
    border: none;
    outline: none;
    appearance: none; }


.main__button {
    border: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 1.6rem;
    text-decoration: none;
    background: $blau;
    color: white;
    font-family: $font;
    font-weight: bold;
    text-align: center;
    padding: 1rem 2rem;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: all 200ms ease;
    width: 100%;
    text-align: center;
    .icn-right {
        margin-left: 1rem; }
    &:after {
        transition: inherit;
        content: "";
        width: 0;
        height: 100%;
        border-radius: 50%;
        background: rgba(white, 0.2);
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%); }
    &:hover {
        &:after {
            width: 100vw;
            height: 100vw; } }
    &.small {
        width: auto;
        display: inline-block;
        margin: 0 1rem; }
    &.white {
        color: $blau;
        width: auto;
        background: white;
        border: 2px solid $blau;
        display: inline-block;
        margin: 0 1rem;
        &:after {
            background: rgba($blau, 0.15); } }
    &#submit_label {
        flex-direction: column;
        span {
            display: block;
            color: white;
            font-family: $font;
            font-weight: bold; }
        .large {
            font-size: 1.8rem; } }
    @media (min-width: $mobile) {
        font-size: 1.8rem; } }

.main__button_content {
    margin-top: 20px;
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    background: $blau;
    color: white;
    font-family: $font;
    font-weight: inherit;
    text-align: center;
    padding: 2rem 3rem;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: all 200ms ease;
    text-transform: inherit;
    img {
        display: inline-block;
        margin-left: 1rem; }
    &:after {
        transition: inherit;
        content: "";
        width: 0;
        height: 100%;
        border-radius: 50%;
        background: rgba(white, 0.1);
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%); }
    &:hover {
        &:after {
            width: 100vw;
            height: 100vw; } }
    @media (min-width: $mobile) {
        font-size: 1.8rem; } }
.toggle {
    width: 5rem;
    min-width: 5rem;
    height: 3rem;
    border: 2px solid $hellgrau;
    border-radius: 50px;
    position: relative;
    display: block;
    cursor: pointer;
    &:after {
        content: "";
        position: absolute;
        height: 2.2rem;
        width: 2.2rem;
        border-radius: 50%;
        background: $hellgrau;
        top: 50%;
        left: 2px;
        transform: translateY(-50%);
        transition: all 150ms ease; }
    &.active {
        &:after {
            left: 2.2rem;
            background: $blau; } } }
.phone__number {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: white;
    border-radius: 5rem;
    padding: 0.7rem 2rem;
    margin-left: 1rem;
    span {
        font-size: 1.6rem;
        font-weight: 700; }
    @media (max-width: $mobile-s) {
        padding: 1rem 0!important;
        width: 4rem;
        height: 4rem;
        display: flex;
        border-radius: 50%;
        span {
            display: none; }
        .phone__icon {
            margin: 0; } } }
.image--button {
    padding: 3rem 7rem 3rem 4rem;
    font-size: 2rem;
    color: white;
    background: url('../img/btn-bg@2x.png') center no-repeat;
    background-size: contain;
    text-decoration: none;
    // text-transform: uppercase
    margin: 5rem auto 5rem;
    display: inline-block;
    font-family: $heading;
    font-weight: normal;
    img {
        margin-left: 1rem; }
    @media (max-width: $mobile-s) {
        line-height: 1.2;
        font-size: 1.6rem!important;
        margin: 1rem auto 2rem;
        img {
            display: none; } } }

